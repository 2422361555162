
<template>
  <div
    v-if="isShow"
    class="goods-alert"
    v-html="text"
  >
  </div>
</template>

<script setup>
import { computed } from 'vue'

/**
 * 商品行提示组件
 */

// props
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   *  新人专享价提示文案
   */
  newUserTips: {
    type: String,
    default: '',
  },
  /**
   * 失去新人专享价资格而售罄的提示文案
   */
  newUserStockOutTips: {
    type: String,
    default: '',
  },
  payMemberGiftStockOutTips: {
    type: String,
    default: '',
  },
  /**
   * 重选提示文案
   */
  reselectTip: {
    type: String,
    default: '',
  },
  /**
   *  可切 MALL 提示文案
   */
  changeMallTip: {
    type: String,
    default: '',
  },
})

const text = computed(() => {
  return props.newUserTips || props.newUserStockOutTips || props.payMemberGiftStockOutTips || props.changeMallTip || props.reselectTip
})
</script>

<style lang="less" scoped>
.goods-alert {
  font-size: 13px;
  color: #bf4123;
}
</style>
