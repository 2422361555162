/**
 * GoodsPrice 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    salePrice: data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.amountWithSymbol,
    salePriceColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem,
    isShowOriginalPrice: !!data.value.aggregateProductBusiness?.priceData?.originalPrice,
    isShowOriginalPriceText: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
    isShowOriginalPricePopover: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip,
    originalPrice: data.value.aggregateProductBusiness?.priceData?.originalPrice?.price?.amountWithSymbol,
    originPriceText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
    originPricePopoverText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip?.desc,
    isShowSuggestPrice: !!data.value.aggregateProductBusiness?.priceData?.lowestPrice,
    suggestPrice: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.price?.amountWithSymbol,
    suggestPriceText: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.description,
    isShowSheinVipIcon: !!data.value.aggregateProductBusiness?.priceIcon?.s3Icon,
    isShowPromotionIcon: true,
    isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.clubIcon,
    isShowTimeIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.timeIcon,
    isShowAppOnlyIcon: false,
    isShowBrandSaleIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.dealsIcon,
    isShowFlashSaleIcon: !!data.value.aggregateProductBusiness?.priceData?.priceIcon?.flashIcon,
    premiumMemberIconColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
    timeIconColor: '#FA6338', // 写死即可
    appOnlyIconColor: '#FA6338', // 写死即可
    brandSaleIconColor: '#FFCD94', // 写死即可
    flashSaleIconColor: '#FFCD94', // 写死即可
  }
}
