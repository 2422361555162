<template>
  <div
    :style="styles"
    class="goods-price"
  >
    <span
      v-if="isShowPromotionIcon"
      class="goods-price__promotion-icon"
    >
      <PromotionIcon
        :is-show-premium-member-icon="isShowPremiumMemberIcon"
        :is-show-time-icon="isShowTimeIcon"
        :is-show-app-only-icon="isShowAppOnlyIcon"
        :is-show-brand-sale-icon="isShowBrandSaleIcon"
        :is-show-flash-sale-icon="isShowFlashSaleIcon"
        :premium-member-icon-color="premiumMemberIconColor"
        :time-icon-color="timeIconColor"
        :app-only-icon-color="appOnlyIconColor"
        :brand-sale-icon-color="brandSaleIconColor"
        :flash-sale-icon-color="flashSaleIconColor"
      />
    </span>
    <RomweVipPrice
      v-if="isShowRomweVipIcon"
      :price="salePrice"
      class="goods-price__romwe-vip-price"
    />
    <SheinVipIcon
      v-if="isShowSheinVipIcon"
      class="goods-price__shein-vip-icon"
    />
    <!-- 销售价 -->
    <span class="goods-price__sale-price">
      <NumberRoll
        :amount="salePrice"
        :box-width="9"
      />
    </span>
    <span
      :class="otherPriceClasses"
    >
      <!-- 划线价 -->
      <span
        v-if="isShowOriginalPrice"
        class="goods-price__original-price"
      >
        <del>
          {{ originalPrice }}
        </del>
        <span v-if="isShowOriginalPriceText">&nbsp;{{ originPriceText }}</span>
        <span
          v-if="isShowOriginalPricePopover"
          class="goods-price__original-price-popover"
        >
          <SPopover
            trigger="hover"
            theme="dark"
            :show-close-icon="true"
            :outside-close="false"
            :content="originPricePopoverText"
          >
            <template #reference>
              <sui_icon_info_12px_1
                size="12"
              />
            </template>
          </SPopover>
        </span>
      </span>
      <!-- 30 天最低价 -->
      <span
        v-if="isShowSuggestPrice"
        class="goods-price__suggest-price"
      >
        <del>{{ suggestPrice }}</del>
        <span>&nbsp;{{ suggestPriceText }}</span>
      </span>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'
import NumberRoll from 'public/src/pages/common/numberRoll/index.vue'
import { SPopover } from '@shein-aidc/sui-popover'
import PromotionIcon from './components/PromotionIcon.vue'
import RomweVipPrice from 'public/src/pages/components/productItem/components/RomweVipPrice.vue'
import SheinVipIcon from './components/SheinVipIcon.vue'

/**
 * 商品行价格组件
 */

// props
const props = defineProps({
  /**
   * 销售价格
   */
  salePrice: {
    type: String,
    default: '',
  },
  /**
   * 销售价格颜色
   */
  salePriceColor: {
    type: String,
    default: '#000'
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示划线价格
   */
  isShowOriginalPrice: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示划线价格提示文案
   */
  isShowOriginalPriceText: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示划线价格气泡
   */
  isShowOriginalPricePopover: {
    type: Boolean,
    default: false
  },
  /**
   * 划线价格
   */
  originalPrice: {
    type: String,
    default: ''
  },
  /**
   * 划线价格文案
   */
  originPriceText: {
    type: String,
    default: ''
  },
  /**
   * 划线价格气泡提示文案
   */
  originPricePopoverText: {
    type: String,
    default: ''
  },
  /**
   * 是否展示建议零售价
   */
  isShowSuggestPrice: {
    type: Boolean,
    default: false
  },
  /**
   * 建议零售价
   */
  suggestPrice: {
    type: String,
    default: ''
  },
  /**
   * 建议零售价文案
   */
  suggestPriceText: {
    type: String,
    default: ''
  },
  /**
   * 是否展示SHEIN会员图标
   */
  isShowSheinVipIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示ROMWE会员图标
   */
  isShowRomweVipIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示促销图标
   */
  isShowPromotionIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示付费会员图标
   */
  isShowPremiumMemberIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示限时促销图标
   */
  isShowTimeIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示APP专享图标
   */
  isShowAppOnlyIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示品牌特卖图标
   */
  isShowBrandSaleIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示闪购图标
   */
  isShowFlashSaleIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 付费会员图标颜色
   */
  premiumMemberIconColor: {
    type: String,
    default: ''
  },
  /**
   * 限时促销图标颜色
   */
  timeIconColor: {
    type: String,
    default: ''
  },
  /**
   * APP专享图标颜色
   */
  appOnlyIconColor: {
    type: String,
    default: ''
  },
  /**
   * 品牌特卖图标颜色
   */
  brandSaleIconColor: {
    type: String,
    default: ''
  },
  /**
   * 闪购图标颜色
   */
  flashSaleIconColor: {
    type: String,
    default: ''
  },
})

// data
// computed
const styles = computed(() => ({
  '--sale-price-color': props.salePriceColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
const otherPriceClasses = computed(() => ({
  'goods-price__other-price': true,
  // 不展示气泡时提示图标时整体文案进行溢出隐藏
  'goods-price__other-price_overflow': !props.isShowOriginalPricePopover,
}))

</script>

<style lang="less" scoped>
.goods-price {
  display: inline-flex;
  align-items: baseline;
  opacity: var(--mask-opacity);
  max-width: 100%;
  &__promotion-icon {
    align-self: center;
    margin-right: 2px;
    font-size: 0;
    flex-shrink: 0;
    height: 16px;
  }
  &__romwe-vip-price {
    align-self: baseline;
    flex-shrink: 0;
  }
  &__shein-vip-icon {
    align-self: baseline;
    flex-shrink: 0;
  }
  &__sale-price {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    font-size: 16px;
    height: 20px;
    font-weight: bold;
    color:var(--sale-price-color);
  }
  &__other-price {
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    &:not(.goods-price__other-price_overflow) {
      .goods-price__original-price {
        width:fit-content;
        overflow: hidden;
      }
      .goods-price__original-price del {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &_overflow {
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .goods-price__original-price {
        display: inline;
      }
    }
  }
  &__original-price {
    display: inline-flex;
    margin-left: 2px;
    font-size: 12px;
    color: #767676;
  }
  &__original-price-popover {
    margin-left: 2px;
    width: 14px;
    flex-shrink: 0;
  }
  &__suggest-price {
    display: inline;
    margin-left: 6px;
    font-size: 12px;
    color: #767676;
  }
  &__original-price-popover {
    margin-left: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;

    /deep/ .sui-popover__trigger {
      font-size: 0;
    }
  }
}
</style>
