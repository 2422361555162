<template>
  <div
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    <div class="goods-title__slot-left">
      <slot name="left"></slot>
    </div>
    <a
      :title="title"
      class="goods-title__content"
      v-html="title"
    >
    </a>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

/**
 * 商品行标题组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['click', 'report', 'expose'])

// props
const props = defineProps({
  /**
   * 标题
   * @type {String}
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 字体颜色
   * @type
   */
  fontColor: {
    type: String,
    default: '#000',
  },
  /**
   * 标题大小
   * @type {Number}
   */
  fontSize: {
    type: String,
    default: '14px',
  },
  /**
   * 标题行高
   * @type {Number}
   */
  lineHeight: {
    type: String,
    default: '16px',
  },
  /**
   * hover 时的样式
   * @type {String}
   */
  hoverStyle: {
    type: String,
    default: 'BOLD',
    validator(val) {
      return ['LINE', 'BOLD'].includes(val)
    }
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: true,
  },
})

// data
// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--font-size': props.fontSize,
    '--line-height': props.lineHeight,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const classes = computed(() => {
  return {
    'goods-title__wrap': true,
    'goods-title_isLine': props.isClick && props.hoverStyle === 'LINE',
    'goods-title_isBold': props.isClick && props.hoverStyle === 'BOLD',
    'goods-title_isClick': props.isClick,
  }
})

// events
const handleClick = () => {
  emit('report')
  if (props.isClick) {
    emit('click')
  }
}
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('GoodsTitle', callback)
}

onMounted(handleExpose)
</script>

<style lang="less" scoped>
.goods-title {
  &__wrap {
    display: flex;
    align-items: center;
    opacity: var(--mask-opacity);
  }
  &_isClick {
    .goods-title__content {
      cursor: pointer;
    }
  }
  &_isLine:hover {
    .goods-title__content {
      text-decoration: underline;
    }
  }
  &_isBold:hover {
    .goods-title__content {
      font-weight: bold;
    }
  }
  &__slot-left {
    flex-shrink: 0;
  }
  &__content {
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--font-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
  }
}
</style>
