import QuickShipTag from './QuickShipTag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const useQuickShipTag = createHelper('QuickShipTag', getProps)

export {
  QuickShipTag,
  useQuickShipTag,
}
