/**
 * BehaviorLabel 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  const labels = data.value.product?.actTags?.filter(item => item.hasAvailableTag === '1') ?? []
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: !!labels.length,
    labels,
    isSwiper: labels.length > 1,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem
  }
}
