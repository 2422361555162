<template>
  <template v-if="isShow">
    <DiscountDetails
      v-if="isHover"
      v-bind="discountDetailsProps"
      class="estimated-price__wrapper"
      @opened="onOpened"
      @closed="onClosed"
    >
      <template #reference="{ visible }">
        <EstimatedReference
          v-bind="estimatedReferenceProps"
          :visible="visible"
        />
      </template>
    </DiscountDetails>
    <EstimatedReference
      v-else
      v-bind="estimatedReferenceProps"
    />
  </template>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

import DiscountDetails from '../../components/discountDetails/index.vue'

import EstimatedReference from './EstimatedReference.vue'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['expose', 'opened', 'closed'])

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否有移入交互
   * @type {Boolean}
   */
  isHover: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  timestamp: {
    type: Number,
    default: null,
  },
  /**
   * 隐藏倒计时
   */
  hideCountdown: {
    type: Boolean,
    default: false,
  },
  estimatedPricePopUp: {
    type: Object,
    default: null,
  },
  textColor: {
    type: String,
    default: '#FA6338',
  },
  lineColor: {
    type: String,
    default: '#FDB9A6',
  },
  bgColor: {
    type: String,
    default: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})


const estimatedReferenceProps = computed(() => ({
  isShowMask: props.isShowMask,
  isHover: props.isHover,
  text: props.text,
  timestamp: props.timestamp,
  hideCountdown: props.hideCountdown,
  textColor: props.textColor,
  lineColor: props.lineColor,
  bgColor: props.bgColor,
  maxWidth: props.maxWidth,
}))

const discountDetailsProps = computed(() => ({
  estimatedPricePopUp: props.estimatedPricePopUp,
}))

const onOpened = () => {
  emit('opened')
}
const onClosed = () => {
  emit('closed')
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose')
      }
      registerExpose('EstimatedPrice', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.estimated-price__wrapper {
  max-width: 100%;
  > div {
    max-width: 100%;
  }
}
</style>
