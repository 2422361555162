import EvoluTag from './EvoluTag.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../../hooks/useHelper'

const useEvoluTag = createHelper('EvoluTag', getProps)

export {
  EvoluTag,
  useEvoluTag,
}
