<template>
  <div
    v-if="isShow"
    class="goods-title-icon" 
  >
    <img
      class="lazyload"
      :data-src="finallyImgUrl"
      :src="constants.LAZY_IMG"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { transformImg } from '@shein/common-function'
import { useInjectConstants } from'../../hooks/useConstant'

/**
 * 商品行标题ICON组件
 */

// hooks
const constants = useInjectConstants()

// props
const props = defineProps({
  /**
   * 是否展示
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * 图片地址
   */
  imgUrl: {
    type: String,
    default: '',
  }
})

// computed
const finallyImgUrl = computed(() => transformImg({ img: props.imgUrl }))
</script>

<style lang="less" scoped>
.goods-title-icon {
  margin-right: 4px;
  width: 48px;
  height: 12px;
  object-fit: contain;
  > img {
    object-fit: contain;
  }
}
</style>
