<template>
  <div
    :style="styles"
    class="goods-price"
  >
    <!-- 价格顶部区域 -->
    <div class="goods-price__top">
      <!-- 顶部左侧 -->
      <!-- 放价格 -->
      <div class="goods-price__left">
        <!-- 营销图标 -->
        <PromotionIcon
          v-if="isShowPromotionIcon"
          class="goods-price__promotion-icon goods-price_mask"
          :is-show-premium-member-icon="isShowPremiumMemberIcon"
          :is-show-time-icon="isShowTimeIcon"
          :is-show-app-only-icon="isShowAppOnlyIcon"
          :is-show-brand-sale-icon="isShowBrandSaleIcon"
          :is-show-flash-sale-icon="isShowFlashSaleIcon"
          :premium-member-icon-color="premiumMemberIconColor"
          :time-icon-color="timeIconColor"
          :app-only-icon-color="appOnlyIconColor"
          :brand-sale-icon-color="brandSaleIconColor"
          :flash-sale-icon-color="flashSaleIconColor"
        />
        <SheinVipIcon
          v-if="isShowSheinVipIcon"
          class="goods-price__shein-vip-icon goods-price_mask"
        />
        <span
          class="goods-price__sale-price goods-price_mask"
          v-html="salePrice"
        ></span>
        <span :class="otherPriceClasses">
          <span
            v-if="isShowOriginalPrice"
            class="goods-price__original-price goods-price_mask"
          >
            <del>
              {{ originalPrice }}
            </del>
            <span v-if="isShowOriginalPriceText">&nbsp;{{ originPriceText }}</span>
            <span
              v-if="isShowOriginalPricePopover"
              class="goods-price__original-price-popover"
            >
              <SPopover
                trigger="hover"
                theme="dark"
                :show-close-icon="true"
                :outside-close="false"
                :content="originPricePopoverText"
              >
                <template #reference>
                  <sui_icon_info_12px_1
                    size="12"
                  />
                </template>
              </SPopover>
            </span>
          </span>

          <!-- 由插入的组件自己控制 mask -->
          <slot name="price-after"></slot>
          <!-- 由插入的组件自己控制 mask -->

        </span>
      </div>
      <!-- 顶部右侧 -->
      <!-- 放商品操作：比如数量变更 -->
      <div class="goods-price__right">
        <slot name="top-right"></slot>
      </div>
    </div>
    <!-- 价格底部区域 -->
    <!-- 放 30 天最低价提示文案 -->
    <div class="goods-price__bottom">
      <span
        v-if="isShowSuggestPrice"
        class="goods-price__suggest-price"
      >
        <del>{{ suggestPrice }}</del>
        <span>&nbsp;{{ suggestPriceText }}</span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'
import { SPopover } from '@shein-aidc/sui-popover'
import PromotionIcon from './components/PromotionIcon.vue'
import SheinVipIcon from './components/SheinVipIcon.vue'

/**
 * 商品行价格组件V2
 */

// props
const props = defineProps({
  /**
   * 销售价格
   */
  salePrice: {
    type: String,
    default: '',
  },
  /**
   * 销售价格颜色
   */
  salePriceColor: {
    type: String,
    default: '#000'
  },
  /**
   * 是否展示遮罩
   * @type {Boolean}
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示划线价格
   */
  isShowOriginalPrice: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示划线价格提示文案
   */
  isShowOriginalPriceText: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示划线价格气泡
   */
  isShowOriginalPricePopover: {
    type: Boolean,
    default: false
  },
  /**
   * 划线价格
   */
  originalPrice: {
    type: String,
    default: ''
  },
  /**
   * 划线价格文案
   */
  originPriceText: {
    type: String,
    default: ''
  },
  /**
   * 划线价格气泡提示文案
   */
  originPricePopoverText: {
    type: String,
    default: ''
  },
  /**
   * 是否展示建议零售价
   */
  isShowSuggestPrice: {
    type: Boolean,
    default: false
  },
  /**
   * 建议零售价
   */
  suggestPrice: {
    type: String,
    default: ''
  },
  /**
   * 建议零售价文案
   */
  suggestPriceText: {
    type: String,
    default: ''
  },
  /**
   * 是否展示SHEIN会员图标
   */
  isShowSheinVipIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示ROMWE会员图标
   */
  isShowRomweVipIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示促销图标
   */
  isShowPromotionIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示付费会员图标
   */
  isShowPremiumMemberIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示限时促销图标
   */
  isShowTimeIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示APP专享图标
   */
  isShowAppOnlyIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示品牌特卖图标
   */
  isShowBrandSaleIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 是否展示闪购图标
   */
  isShowFlashSaleIcon: {
    type: Boolean,
    default: false
  },
  /**
   * 付费会员图标颜色
   */
  premiumMemberIconColor: {
    type: String,
    default: ''
  },
  /**
   * 限时促销图标颜色
   */
  timeIconColor: {
    type: String,
    default: ''
  },
  /**
   * APP专享图标颜色
   */
  appOnlyIconColor: {
    type: String,
    default: ''
  },
  /**
   * 品牌特卖图标颜色
   */
  brandSaleIconColor: {
    type: String,
    default: ''
  },
  /**
   * 闪购图标颜色
   */
  flashSaleIconColor: {
    type: String,
    default: ''
  },
})

// data
// computed
const styles = computed(() => ({
  '--sale-price-color': props.salePriceColor,
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
const otherPriceClasses = computed(() => ({
  'goods-price__other-price': true,
  // 不展示气泡时提示图标时整体文案进行溢出隐藏
  'goods-price__other-price_overflow': !props.isShowOriginalPricePopover,
}))

</script>

<style lang="less" scoped>
.goods-price {
  &__top {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  &__left {
    display: inline-flex;
    align-items: baseline;
    flex: 1;
    overflow: hidden;
  }
  &_mask {
    opacity: var(--mask-opacity);
  }
  &__right {
    flex-shrink: 0;
  }
  &__bottom {
    opacity: var(--mask-opacity);
  }
  &__promotion-icon {
    align-self: center;
    margin-right: 2px;
    font-size: 0;
    flex-shrink: 0;
    height: 16px;
  }

  &__shein-vip-icon {
    align-self: baseline;
    flex-shrink: 0;
  }

  &__sale-price {
    display: flex;
    align-items: baseline;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: var(--sale-price-color);
    direction: ltr;
  }

  &__other-price {
    display: inline-flex;
    width: 100%;
    overflow: hidden;

    &:not(.goods-price__other-price_overflow) {
      .goods-price__original-price {
        width: fit-content;
        overflow: hidden;
      }

      .goods-price__original-price del {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_overflow {
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .goods-price__original-price {
        display: inline;
      }
    }
  }

  &__original-price {
    display: inline-flex;
    margin-left: 2px;
    font-size: 12px;
    color: #767676;
  }

  &__original-price-popover {
    margin-left: 2px;
    width: 14px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    /deep/ .sui-popover__trigger {
      font-size: 0;
    }
  }

  .goods-price__suggest-price {
    display: inline;
    font-size: 10px;
    color: #767676;
  }
}
</style>
