import { toRef, toRefs, ref } from 'vue'
import { useHelpers } from './useHelper'
import { useProvideConstants } from './useConstant'
import { useProvideLanguage } from './useLanguage'
import { useExpose, useProvideRegisterExpose } from './useExpose'

/**
 * 商品行模板 props
 */
export const templateProps = {
  /**
   * 商品行数据
   * @param {Object} data
   */
  data: {
    type: Object,
    default: () => ({})
  },
  /**
   * 当前模板启用的原子组件，不配置默认启用所有（不推荐）
   */
  use: {
    type: Array,
    default: () => [],
  },
  /**
   * 原子组件配置
   */
  config: {
    type: Object,
    default: () => ({}),
  },

  /**
   * 通用常量配置
   * @param {Object} constant
   * @property {Boolean} RESOURCE_SDK
   * @property {Boolean} LAZY_IMG
   * @property {Boolean} GBCssRight
   */
  constant: {
    type: Object,
    default: () => ({}),
  },
  /**
   * 多语言包
   * @param {Object} language
   * @description 详细见 controllers/i18n/cart_item.js
   */
  language: {
    type: Object,
    default: () => ({}),
  },
}

/**
 * 使用模板
 * @param {Object} props - 模板 Props
 * @param {Array<Function>} - 当前模板使用的 Helper 函数
 * @returns {Object}
 */
export const useTemplate = (props, helpers) => {
  // props
  const data = toRef(props, 'data')
  const config = toRefs(props.config)
  const language = toRef(props, 'language')
  const constant = toRef(props, 'constant')
  const use = toRef(props, 'use')

  // ref
  const tempRef = ref(null)


  // helpers data
  const helpersData = useHelpers({
    helpers,
    data,
    config,
    constant,
    language,
    use,
  })
  // methods
  const registerExpose = useExpose(tempRef)

  // provide
  useProvideRegisterExpose(registerExpose)
  useProvideConstants(constant)
  useProvideLanguage(language)

  return {
    // props
    data,
    config,

    // ref
    tempRef,

    // data
    helpersData,

    // methods
    registerExpose,
  }
}
