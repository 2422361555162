<template>
  <div
    v-if="isShow"
    class="evolu-tag"
    :style="style"
  >
    <div class="evolu-tag__text">
      {{ text }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: 'evoluSHEIN',
  },
  bgColor: {
    type: String,
    default: '#A86104',
  },
  textColor: {
    type: String,
    default: '#FFF5E9',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less" scoped>
.evolu-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
