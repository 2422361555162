import GoodsTitle from './GoodsTitle.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsTitle = createHelper('GoodsTitle', getProps)

export {
  GoodsTitle,
  useGoodsTitle,
}
