import GoodsPriceV1 from './GoodsPriceV1.vue'
import GoodsPriceV2 from './GoodsPriceV2.vue'
import { getProps } from './helper.js'
import { createHelper } from '../../hooks/useHelper'

const useGoodsPrice = createHelper('GoodsPrice', getProps)

export {
  GoodsPriceV1,
  GoodsPriceV2,
  useGoodsPrice,
}
