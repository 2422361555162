<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
    @click="handleClick"
  >
    <div class="promotion-tag__text">
      {{ text }}
    </div>
    <s-popover
      v-if="tip"
      trigger="hover"
      fix-disappear-position
      :append-to-body="false"
      :delay-render="300"
      :content="tip"
      :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px', whiteSpace: 'normal'}"
    >
      <template #reference>
        <sui_icon_info_12px_1
          size="12px"
          color="#959595"
          class="promotion-tag__icon-tip"
        />
      </template>
    </s-popover>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="promotion-tag__line"></div>
        <template v-if="displayDateOrTime && dateFormat">
          <div class="promotion-tag__date">
            {{ dateFormat }}
          </div>
        </template>
        <template v-else>
          <CartCountdown
            v-if="timestamp"
            class="promotion-tag__count-down"
            v-bind="cartCountDownProps"
          />
        </template>
      </template>
    </ClientOnly>
    <sui_icon_closed_36px
      v-if="isClick"
      size="12px"
      class="promotion-tag__icon"
      :is-rotate="constants.GBCssRight"
    />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'

import { ClientOnly } from '@sheinfe/vue-client-only'
import { sui_icon_closed_36px, sui_icon_info_12px_1 } from '@shein-aidc/icon-vue3'

import { hexToRgb, timeTransformer } from '@shein/common-function'
import { MESSAGE_DATE } from '@/public/src/pages/common/biz_helper/timer_format/dictionary.js'

import CartCountdown from '../../../components/CartCountdown.vue'
import { useInjectConstants } from'../../../hooks/useConstant'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

// hooks
const constants = useInjectConstants()
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['click', 'report', 'expose'])

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否可点击
   * @type {Boolean}
   */
  isClick: {
    type: Boolean,
    default: false,
  },
  /**
   * 标签类型 ( flash | club-gift | coupon )
   * 该值决定了标签样式
   */
  type: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  tip: {
    type: String,
    default: '',
  },
  /**
   * 隐藏倒计时
   */
  hideCountdown: {
    type: Boolean,
    default: false,
  },
  timestamp: {
    type: Number,
    default: null,
  },
  endInTip: {
    type: String,
    default: '',
  },
  /**
   * 倒计时显示的格式
   * true: Ends in 2024/06/14
   * false: 72:59:58
   */
  displayDateOrTime: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default: null,
  },
  textColor: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classnames = computed(() => {
  const cls = [
    'promotion-tag',
    {
      'promotion-tag__pointer': props.isClick,
      'has-count-down': showCountdown.value,
    }
  ]
  if (props.type) cls.push(`promotion-tag__${props.type}`)
  return cls
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--text-color': props.textColor,
  '--bg-color': props.bgColor,
  '--rgba-bg-color': hexToRgb({ hex: props.bgColor, opa: props.type === 'flash' ? '0.3' : '0.5' }),
  '--rgba-line-color': hexToRgb({ hex: props.textColor, opa: '0.4' }),
  maxWidth: props.maxWidth,
}))

const dateFormat = computed(() => {
  if (typeof window === 'undefined') return ''

  if (!props.timestamp || !props.endInTip) return ''

  const text = timeTransformer({
    time: props.timestamp * 1000,
    sDateMap: MESSAGE_DATE,
  })
  return `${props.endInTip} ${text}`
})

const showCountdown = computed(() => {
  if (props.hideCountdown || !props.timestamp) return false

  if (props.displayDateOrTime) return !!dateFormat.value

  return true
})

const cartCountDownProps = computed(() => ({
  timestamp: +props.timestamp,
  hideOnZero: true,
  reloadOnZero: true,
  fontColor: '#222',
  colonColor: '#222',
  fontBgColor: '#fff',
}))

const handleClick = () => {
  emit('report', props.data)
  if (props.isClick) {
    emit('click', props.data)
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('expose', props.data)
      }
      registerExpose('PromotionTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less" scoped>
.promotion-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  vertical-align: middle;
  opacity: var(--mask-opacity);
  background: var(--bg-color);

  &.has-count-down {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--rgba-bg-color) 100%);
  }

  &.promotion-tag__club-gift {
    &.has-count-down {
      background: linear-gradient(90deg, #FFECE9 0%, rgba(#FFECE9, .3) 100%);
    }

    .promotion-tag__line {
      border-left-color: var(--rgba-line-color);
    }

    .promotion-tag__icon {
      color: var(--text-color);
    }
  }

  &.promotion-tag__coupon {
    position: relative;
    color: var(--text-color);
    background: @sui_color_white;
    border: 1px solid var(--text-color);
    border-radius: 1px;
    overflow: hidden;
    mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--text-color);
      border-radius: 50%;
      top: 50%;
      /* rtl:begin:ignore */
      margin-top: -4px;
      left: -5px;
    }
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-color);
  }

  &__icon-tip {
    margin-left: 4px;
    line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
  }

  &__line {
    flex-shrink: 0;
    margin: 0 6px;
    height: 10px;
    border-left: 1px solid @sui_color_gray_dark3;
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__date {
    flex-shrink: 0;
    color: var(--text-color);
  }

  &__pointer {
    cursor: pointer;
  }
}
</style>
