import { computed, readonly } from 'vue'

const merge = (a = {}, b = {}) => ({ ...a, ...b })
const isFn = (fn) => typeof fn === 'function'
const isObj = (val) => Object.prototype.toString.call(val) === '[object Object]'

/**
 * 使用助手函数 hook
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前原子组件配置
 * @param {Object} constant -  全局通用常量
 * @param {Object} language -  全局通用的语言包
 * @param {String} name -  当前原子组件配置名称
 * @param {Array} use -  当前模板是否使用当前原子组件
 * @param {Function} [getProps] - 获取 props 方法
 * @param {Function} [getEvents] - 获取 events 方法
 * @returns {attrs, isShow, type, position}
 */
function useHelper({
  data,
  config = {},
  constant = {},
  language = {},
  name,
  use,
  getProps,
  getEvents,
}) {
  // props
  const propsParams = {
    data: readonly(data),
    config: readonly(config),
    constant: readonly(constant),
    language: readonly(language),
  }
  const useStatus = computed(() => {
    return use.value.length ? use.value.includes(name) : true
  })
  const props = computed(() => {
    // 不使用不计算 props
    if (!useStatus.value) return {}
    const defaultProps = isFn(getProps) ? getProps(propsParams) : {}
    const userProps = isFn(config.value?.props)
      ? config.value?.props(propsParams)
      : config.value?.props ?? {}

    return {
      ...defaultProps,
      ...userProps,
    }
  })

  // events
  const events = computed(() => {
    // 不使用不计算 props
    if (!useStatus.value) return {}
    const defaultEvents = isFn(getEvents) ? getEvents() : {}
    const userEvents = isObj(config.value?.events) ? config.value?.events : {}
    const mergedEvents = merge(defaultEvents, userEvents)

    const eventHandlers = {}
    for (const [key, handler] of Object.entries(mergedEvents)) {
      eventHandlers[key] = (event) => handler({ data, props, constant, language, event })
    }
  
    return eventHandlers
  })

  // merge props and events
  const attrs = computed(() => ({
    ...props.value,
    ...events.value,
  }))

  // other params
  const other = computed(() => ({
    isShow: useStatus.value,
    type: config.value?.type ?? '',
    position: config.value?.position ?? '',
  }))

  return computed(() => ({
    attrs: attrs.value,
    ...other.value,
  }))
}

/**
 * 创建助手函数
 * @param {String} name - 当前原子组件配置 key
 * @param {Function} getProps - 获取 props 方法
 * @param {Function} getEvents - 获取 events 方法
 * @returns {Function}
 */
export function createHelper(name, getProps, getEvents) {
  return ({ data, config, constant, language, use }) =>
    useHelper({
      data,
      constant,
      language,
      name,
      use,
      config: config[name],
      getProps,
      getEvents,
    })
}

/***
 * 批量使用助手函数hooks
 * @param {Array} helpers - 原子组件助手函数
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前模板配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 当前语言包
 */
export const useHelpers = ({
  helpers = [],
  data,
  config,
  constant,
  language,
  use,
}) => helpers.map((helper) => helper({ data, config, constant, language, use }))
