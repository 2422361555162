/**
 * GoodsTitle 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    title: data.value.product?.goods_name,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem,
    fontColor: '#000',
    fontSize: '14px',
    lineHeight: '16px',
    hoverStyle: 'BOLD',
    isClick: !isGiftItem
  }
}
