<template>
  <p
    v-if="isShow"
    class="cart-countdown"
    :style="styles"
  >
    <span class="cart-countdown__content">
      <span
        v-if="diff.days"
        class="cart-countdown__number cart-countdown__days"
      >{{ daysText }}</span>
      <span class="cart-countdown__number cart-countdown__hours">{{ twoNums(diff.hours) }}</span>
      <span class="cart-countdown__colon">:</span>
      <span class="cart-countdown__number cart-countdown__mins">{{ twoNums(diff.minutes) }}</span>
      <span class="cart-countdown__colon">:</span>
      <span class="cart-countdown__number cart-countdown__seconds">{{ twoNums(diff.seconds) }}</span>
    </span>
  </p>
</template>
<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import i18n from '@/public/src/pages/common/biz_helper/i18n'

const props = defineProps({
  timestamp: {
    type: Number,
    default: 0,
  },
  isLeftTime: {
    type: Boolean,
    default: false,
  },
  // 倒计时是否显示天数
  showDays: {
    type: Boolean,
    default: false,
  },
  // 倒计时归零时，隐藏倒计时组件
  hideOnZero: {
    type: Boolean,
    default: false,
  },
  // 倒计时归零时，刷新页面
  reloadOnZero: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: '10px',
  },
  fontColor: {
    type: String,
    default: '#222',
  },
  fontBgColor: {
    type: String,
    default: 'transparent',
  },
  colonColor: {
    type: String,
    default: '#222',
  },
  colonSpace: {
    type: String,
    default: '2px',
  },
})

let timer = null

const styles = computed(() => ({
  '--font-size': props.fontSize,
  '--font-color': props.fontColor,
  '--font-bg-color': props.fontBgColor,
  '--colon-color': props.colonColor,
  '--colon-space': props.colonSpace,
}))

const isShow = ref(true)

const language = ref({})

const diff = ref({
  days: '',
  hours: '',
  minutes: '',
  seconds: '',
})

const _timestamp = computed(() => {
  let stamp = props.timestamp * 1000
  if (props.isLeftTime) stamp = Date.now() + stamp
  return stamp
})

const daysText = computed(() => {
  const days = diff.value.days
  let suffix = ''
  if (days > 0) {
    suffix = days === 1 ? ` ${language.value.SHEIN_KEY_PC_15173 || 'Day'}` : ` ${language.value.SHEIN_KEY_PC_17212 || 'Days'}`
  }
  return `${days}${suffix}`
})

onBeforeMount(() => {
  updateLanguage()
  init()
})

const updateLanguage = async () => {
  const res = await i18n.loadByQueue('promotion')
  language.value = res
}

const init = () => {
  const countdownInfo = getCountdownInfo(_timestamp.value)
  let days
  let hours
  const minutes = countdownInfo.diffMinutes
  const seconds = countdownInfo.diffSeconds

  if (props.showDays) {
    days = countdownInfo.diffDays
    hours = countdownInfo.diffHours
  } else {
    days = 0
    hours = countdownInfo.diffHours + 24 * countdownInfo.diffDays
  }

  diff.value = {
    days,
    hours,
    minutes,
    seconds,
  }

  setTimer()
}

const setTimer = () => {
  if (timer) return
  timer = setInterval(() => {
    const s = diff.value.seconds
    if (s - 1 >= 0) {
      diff.value = {
        ...diff.value,
        seconds: s - 1,
      }
      return
    }

    const m = diff.value.minutes
    if (m - 1 >= 0) {
      diff.value = {
        ...diff.value,
        minutes: m - 1,
        seconds: 59,
      }
      return
    }

    const h = diff.value.hours
    if (h - 1 >= 0) {
      diff.value = {
        ...diff.value,
        hours: h - 1,
        minutes: 59,
        seconds: 59,
      }
      return
    }

    const d = diff.value.days
    if (d - 1 >= 0) {
      diff.value = {
        days: d - 1,
        hours: 23,
        minutes: 59,
        seconds: 59,
      }
      return
    }

    clearTimer()

    if (props.hideOnZero) isShow.value = false
    if (props.reloadOnZero) window.location.reload(true)
  }, 1000)
}

const clearTimer = () => {
  if (timer) clearInterval(timer)
  timer = null
}

const getCountdownInfo = (endTimestamp) => {
  let diffTime = endTimestamp - Date.now()
  if (diffTime < 0) diffTime = 0
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}

const twoNums = (val) => {
  if (typeof val === 'number' && !isNaN(val)) {
    return val < 10 ? `0${val}` : `${val}`
  }
  return ''
}

</script>
<style lang="less" scoped>
.cart-countdown {
  &__content {
    display: inline-flex;
    align-items: center;
    /* rtl:begin:ignore */
    direction: ltr;
  }

  &__number {
    display: inline;
    padding: 1px 2px;
    font-size: 10px;
    font-size: var(--font-size);
    color: @sui_color_main;
    color: var(--font-color);
    background: var(--font-bg-color);
  }

  &__days {
    margin-right: 2px;
  }

  &__colon {
    padding-left: var(--colon-space);
    padding-right: var(--colon-space);
    font-size: 10px;
    color: @sui_color_main;
    color: var(--colon-color);
  }
}
</style>
