<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <span class="quick-ship-tag__content">
      <img
        v-if="prefixIcon"
        :src="prefixIcon"
        alt=""
        class="quick-ship-tag__icon"
      />
      <span class="quick-ship-tag__text">{{ text }}</span>
      <span
        v-if="isShowTimeText"
        class="quick-ship-tag__time"
      >
        <span
          v-if="timeText"
          class="quick-ship-tag__time-line"
        >
          |
        </span>
        <span
          v-if="timeText"
          class="quick-ship-tag__time-text"
        >
          &#x202A;{{ timeText }}
        </span>
      </span>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'

const props = defineProps({
  /**
   * 是否展示当前组件
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   * @type {Boolean
   * 是否展示遮罩
   */
  isShowMask: {
    type: Boolean,
    default: false,
  },
  /**
   * @type {String}
   * 标签文案
   */
  text: {
    type: String,
    default: '',
  },
  /**
   * 时效信息
   * @type {String}
   */
  timeText: {
    type: String,
    default: '',
  },
  /**
   * 是否展示时效信息
   */
  isShowTimeText: {
    type: Boolean,
    default: false,
  },
  /**
   * 是否展示新样式
   * @type {Object}
   */
  isShowNewStyle: {
    type: Boolean,
    default: false,
  },
  prefixIcon: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '#ECFCF3',
  },
  textColor: {
    type: String,
    default: '#198055',
  },
  /**
   * 达到指定宽度时，溢出隐藏
   * 例如：120px
   */
  maxWidth: {
    type: String,
    default: '100%',
  },
})

const classes = computed(() => [
  'quick-ship-tag',
  {
    'quick-ship-tag__new': props.isShowNewStyle,
    'quick-ship-tag__show-time': props.isShowTimeText,
  }
])

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.6 }),
}))
</script>

<style lang="less" scoped>
.quick-ship-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &.quick-ship-tag__new {
    border-radius: 2px;
    box-shadow: inset 0 0 0 .5px rgba(25, 128, 85, .2);

    .quick-ship-tag__text {
      padding-right: 1px;
      font-style: italic;
    }
  }
  &.quick-ship-tag__show-time {
    .quick-ship-tag__text {
      flex-shrink: 0;
    }
  }
  &__content {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
  }
  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
  &__time {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
  }
  &__time-line {
    flex-shrink: 0;
    color: var(--separator-color);
    font-size: 10px;
    padding: 0 2px;
  }
  &__time-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
}
</style>
