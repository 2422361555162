<template>
  <div
    v-if="isShow"
    class="reduce-price-hint"
    :style="styles"
  >
    <img
      v-if="isShowPrefixIcon && prefixIcon"
      :src="prefixIcon"
      alt=""
      class="reduce-price-hint__icon"
    />
    <div
      class="reduce-price-hint__text"
      v-html="hintText"
    >
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

/**
 * 商品行降价提示组件
 */

// props
const props = defineProps({
  /**
   * 是否展示
   * @type {Boolean}
   */
  isShow: {
    type: Boolean,
    default: false,
  },
  /**
   *  是否展示前缀ICON
   */
  isShowPrefixIcon: {
    type: Boolean,
    default: false,
  },
  prefixIcon: {
    type: String,
    default: ''
  },
  /**
   * 提示文案
   * @type {String}
   */
  hintText: {
    type: String,
    default: '',
  },
  /**
   * 文案颜色
   */
  textColor: {
    type: String,
    default: '#C44A01',
  },
})

const styles = computed(() => ({
  '--text-color': props.textColor,
}))
</script>

<style lang="less" scoped>
.reduce-price-hint {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-color); 
  .reduce-price-hint__icon {
    width: 12px;
    height: 12px;
  }
  .reduce-price-hint__text {
    strong {
      font-weight: bold;
    }
  }
}
</style>
